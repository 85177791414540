
export default {
  methods: {
    openSocialMedia (url) {
      window.open(url, '_blank')
    },
    selectLanguage (val) {
      this.$store.commit('public/SET_LANGUAGE', val)
      this.$i18n.setLocaleCookie(val)
    }
  }
}
