
import vsButton from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
Vue.use(vsButton)

export default {
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    dark: {
      type: Boolean,
      default: true
    },
    ctaCustom: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ctaClick () {
      this.$emit('ctaClick')
    }
  }
}
