
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    features: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ctaClick () {
      this.$emit('ctaClick')
    }
  }
}
