
export default {
  data: () => ({
    url: '',
    protocol: 'link-internal',
    img: ''
  }),
  mounted () {
    this.fetchFloatingBanner()
  },
  methods: {
    handleClick () {
      if (this.protocol === 'link-internal') {
        const url = this.url.slice(1)
        this.$goTo(url)
      } else {
        window.open(this.url, '_blank')
      }
    },
    async fetchFloatingBanner () {
      await this.$store.dispatch('public/getFloatingBanner')
        .then((res) => {
          this.img = res.button || ''
          this.protocol = res.protocol
          this.url = res.target || ''
        })
    }
  }
}
