
export default {
  props: {
    file: {
      type: Array,
      default: () => []
    },
    format: {
      type: Array,
      default: () => ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/heic', 'video/mp4', 'video/hevc']
    }
  },
  data: () => ({
    imageUrl: null,
    media_file: '',
    video_url: ''
  }),
  methods: {
    handleExceed (files, fileList) {
      this.$message({
        message: `The limit is 8, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`,
        type: 'warning',
        duration: 10000
      })
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob.raw)
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async handleAvatarSuccess (file, fileList) {
      const valid = await this.beforeAvatarUpload(file.raw)
      if (!valid) { return }
      const thumb = URL.createObjectURL(file.raw)
      if (file.raw && ['video/mp4', 'video/hevc'].includes(file.raw.type)) {
        this.video_url = thumb
      } else {
        this.imageUrl = thumb
      }
      this.media_file = file.raw
      this.$emit('onUpload', {
        file: this.media_file,
        thumb,
        files: fileList
      })
    },
    beforeAvatarUpload (file) {
      let isJfif = false
      if (file.name) {
        const filename = file.name.split('.')
        if (filename.length > 0 && filename[1] === 'jfif') {
          isJfif = true
        }
      }
      const isJPG = this.format.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2

      if (isJfif || !isJPG) {
        this.$notify.error({
          message: 'Format gambar tidak sesuai',
          offset: 100
        })
        return false
      }
      if (!isLt2M) {
        this.$notify.error({
          message: 'Ukuran Foto tidak bisa melebihi dari 5MB!',
          offset: 100
        })
        return false
      }
      return isJPG && isLt2M
    },
    handleRemove (file, fileList) {
      this.$emit('deleteImage', fileList)
    }
  }
}
